/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import loadable from "@loadable/component";
import {
  initializeAppInConfluenceContext,
  loadScriptInHTMLHead,
} from "./confluence-app-initializer";

import "./css/overrides.css";
import { themes } from "./theme-colors";
import { ThemeContext } from "./theme-context";
import { FlagsProvider } from "@atlaskit/flag";
import { useTranslation } from "react-i18next";

import { useLanguageDetect } from "./Shared/Hooks/useLanguageDetect";

function getParameterByName(name, url = window.location.href) {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const DEFAULT_COLOR_MODE = "light";

const Modes = {
  VIEW: "view",
  EDITOR: "editor",
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function css(element, style) {
  for (const property in style) {
    element.style[property] = style[property];
  }
}

const ButtonView = loadable(() => import("./button/ButtonMacro"));
const ButtonEditor = loadable(() =>
  import("./Shared/Components/Editors/ButtonEditor")
);

const ImageCarouselView = loadable(() =>
  import("./image-carousel/CarouselView")
);
const ImageCarouselEditor = loadable(() =>
  import("./image-carousel/CarouselEditor")
);
const ImageCarouselFullscreen = loadable(() =>
  import("./image-carousel/CarouselFullscreenDialog")
);

const AutomatedHeadingsEditor = loadable(() =>
  import("./automated-numbered-headings/AutomatedHeadingEditor")
);

const RatingView = loadable(() => import("./rating/plugin/RateSearchMacro"));
const RatingEditor = loadable(() =>
  import("./rating/plugin/RateSearchMacroEditor")
);

const EasyHTMLView = loadable(() => import("./easy-html/plugin/EasyHtml"));
const EasyHTMLEditor = loadable(() =>
  import("./easy-html/plugin/EasyHtmlEditor")
);

const DividerView = loadable(() => import("./divider/DividerMacro"));
const DividerEditor = loadable(() => import("./divider/DividerMacroEditor"));

const Countdown = loadable(() => import("./countdown-v2/CountdownMacro"));

const LegacyCountdownView = loadable(() =>
  import("./countdown/CountdownMacro")
);
const LegacyCountdownEditor = loadable(() =>
  import("./countdown/CountdownMacroEditor")
);

const TabsView = loadable(() => import("./tabs/TabsMacro"));
const TabsEditor = loadable(() => import("./tabs/TabsMacroEditor"));

const IncomingLinksView = loadable(() =>
  import("./incoming-links/IncomingLinks")
);
const IncomingLinksEditor = loadable(() =>
  import("./incoming-links/IncomingLinksEditor")
);

const HiddenContentView = loadable(() =>
  import("./hidden-content/HiddenContent")
);
const HiddenContentEditor = loadable(() =>
  import("./hidden-content/HiddenContentEditor")
);

const PageBreakView = loadable(() => import("./page-break/PageBreakView"));
const PageBreakEditor = loadable(() => import("./page-break/PageBreakEditor"));

const DropdownView = loadable(() => import("./dropdown/DropdownMacro"));
const DropdownEditor = loadable(() => import("./dropdown/DropdownMacroEditor"));

const DueDateView = loadable(() => import("./due-date/DueDate"));
const DueDateEditor = loadable(() => import("./due-date/DueDateEditor"));

const TooltipAndFootnoteView = loadable(() =>
  import("./tooltip-and-footnote/TooltipFootnote")
);
const TooltipAndFootnoteEditor = loadable(() =>
  import("./tooltip-and-footnote/TooltipFootnoteEditor")
);

const FootnoteDisplayView = loadable(() =>
  import("./footnote-display/FootnoteDisplayMacro")
);
const FootnoteDisplayEditor = loadable(() =>
  import("./footnote-display/FootnoteDisplayMacroEditor")
);

const TitleAndTextView = loadable(() =>
  import("./section/Components/SectionView")
);
const TitleAndTextEditor = loadable(() =>
  import("./section/Components/SectionEditor")
);

const CardsView = loadable(() => import("./cards-v2/CardsMacro"));
const CardsEditor = loadable(() =>
  import("./cards-v2/components/editor/CardsMacroEditor")
);

const LegacyCardsView = loadable(() => import("./cards/CardsMacro"));
const LegacyCardsEditor = loadable(() =>
  import("./cards/components/editor/CardsMacroEditor")
);

const DesignerView = loadable(() =>
  import("./elementor/Components/ElementorView")
);
const DesignerEditor = loadable(() =>
  import("./elementor/Components/ElementorEditor")
);

const PanelView = loadable(() => import("./panel/PanelView"));
const PanelEditor = loadable(() => import("./panel/PanelEditor"));

const ImageSliderView = loadable(() =>
  import("./image-slider/Components/ImageSliderView")
);
const ImageSliderEditor = loadable(() =>
  import("./image-slider/Components/ImageSliderEditor")
);

const AnnouncementView = loadable(() =>
  import("./announcements/Components/View")
);
const AnnouncementEditor = loadable(() =>
  import("./announcements/Components/Editor")
);

const MacrosuiteConfiguration = loadable(() =>
  import("./configuration/components/MacrosuiteConfiguration")
);

const RatingPanel = loadable(() => import("./rating/plugin/RateMacro"));

const StyleguideLanding = loadable(() =>
  import("./styleguide_new/pages/Landing")
);
const StyleguideNews = loadable(() => import("./styleguide_new/pages/News"));
const StyleguideMacrosuite = loadable(() =>
  import("./styleguide_new/pages/Macrosuite")
);
const StyleguidePermissions = loadable(() =>
  import("./styleguide_new/pages/Permissions")
);

const TabGroupView = loadable(() =>
  import("./nested-tabs/tab-group/components/View")
);
const TabGroupEditor = loadable(() =>
  import("./nested-tabs/tab-group/components/Editor")
);

const TabView = loadable(() => import("./nested-tabs/tab/components/View"));
const TabEditor = loadable(() => import("./nested-tabs/tab/components/Editor"));

const routes = {
  button: {
    View: <ButtonView />,
    Editor: <ButtonEditor editorType="macro" />,
  },
  "image-carousel": {
    View: <ImageCarouselView />,
    Editor: <ImageCarouselEditor />,
  },
  "numbered-headings": {
    View: null,
    Editor: <AutomatedHeadingsEditor />,
  },
  rating: {
    View: <RatingView />,
    Editor: <RatingEditor />,
  },
  "easy-html": {
    View: <EasyHTMLView />,
    Editor: <EasyHTMLEditor />,
  },
  divider: {
    View: <DividerView />,
    Editor: <DividerEditor />,
  },
  "legacy-countdown": {
    View: <LegacyCountdownView />,
    Editor: <LegacyCountdownEditor />,
  },
  countdown: {
    View: <Countdown isEditing={false} />,
    Editor: <Countdown isEditing />,
  },
  tabs: {
    View: <TabsView />,
    Editor: <TabsEditor />,
  },
  "hidden-content": {
    View: <HiddenContentView />,
    Editor: <HiddenContentEditor />,
  },
  "incoming-links": {
    View: <IncomingLinksView />,
    Editor: <IncomingLinksEditor />,
  },
  "page-break": {
    View: <PageBreakView />,
    Editor: <PageBreakEditor />,
  },
  dropdown: {
    View: <DropdownView />,
    Editor: <DropdownEditor />,
  },
  "due-date": {
    View: <DueDateView />,
    Editor: <DueDateEditor />,
  },
  "tooltip-and-footnote": {
    View: <TooltipAndFootnoteView />,
    Editor: <TooltipAndFootnoteEditor />,
  },
  "footnote-display": {
    View: <FootnoteDisplayView />,
    Editor: <FootnoteDisplayEditor />,
  },
  "title-and-text": {
    View: <TitleAndTextView />,
    Editor: <TitleAndTextEditor />,
  },
  cards: { View: <LegacyCardsView />, Editor: <LegacyCardsEditor /> },
  "cards-v2": {
    View: <CardsView />,
    Editor: <CardsEditor editorType="macro" />,
  },
  designer: { View: <DesignerView />, Editor: <DesignerEditor /> },
  panel: {
    View: <PanelView />,
    Editor: <PanelEditor editorType="macro" />,
  },
  "image-slider": { View: <ImageSliderView />, Editor: <ImageSliderEditor /> },
  announcement: { View: <AnnouncementView />, Editor: <AnnouncementEditor /> },
  "tab-group": { View: <TabGroupView />, Editor: <TabGroupEditor /> },
  tab: {
    View: <TabView />,
    Editor: <TabEditor />,
  },
};

const router = createBrowserRouter([
  {
    path: "macrosuite-configuration/configuration",
    element: <MacrosuiteConfiguration />,
  },
  {
    path: "rating-panel",
    element: <RatingPanel />,
  },
  {
    path: "carousel/fullscreen",
    element: <ImageCarouselFullscreen />,
  },
  {
    path: "configuration/button-editor",
    element: (
      <QueryClientProvider client={queryClient}>
        <ButtonEditor editorType="configuration" />
      </QueryClientProvider>
    ),
  },
  {
    path: "configuration/card-editor",
    element: (
      <QueryClientProvider client={queryClient}>
        <CardsEditor editorType="configuration" />
      </QueryClientProvider>
    ),
  },
  {
    path: "configuration/panel-editor",
    element: (
      <QueryClientProvider client={queryClient}>
        <PanelEditor editorType="configuration" />
      </QueryClientProvider>
    ),
  },
  {
    path: "styleguide/permissions",
    element: <StyleguidePermissions />,
  },
  {
    path: "styleguide",
    element: <StyleguideLanding />,
  },
  {
    path: "styleguide/news",
    element: <StyleguideNews />,
  },
  {
    path: "styleguide/macrosuite",
    element: <StyleguideMacrosuite />,
  },
  {
    path: "macrosuite-info-banner",
    element: (
      <QueryClientProvider client={queryClient}>
        <></>
      </QueryClientProvider>
    ),
  },
  ...Object.keys(routes)
    .map((macro) => [
      {
        path: `macro/${macro}`,
        element: <>{routes[macro].View}</>,
      },
      {
        path: `macro/editor/${macro}`,
        element: (
          <QueryClientProvider client={queryClient}>
            {routes[macro].Editor}
          </QueryClientProvider>
        ),
      },
    ])
    .flat(),
]);

function setBreakpointStyle(width) {
  const style = document.createElement("style");

  style.textContent = `
    @media (max-width: ${width}px) {
      html, body {
        height: 100%;
      }

      #content, .ac-content {
        max-width: 100% !important;
        height: 100% !important;
        margin: 0 !important;
      }
    }
  `;

  document.head.appendChild(style);
}

function individualMacroStyles(macro, element) {
  const macroConfig = {
    announcement() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
        background: "transparent",
      });
    },
    designer() {
      css(element, { height: "100vh" });
      css(document.body, {
        margin: "0 !important",
        background: "#fff",
      });
    },
    countdown() {
      setBreakpointStyle(1440);
      css(element, {
        height: "80vh",
        maxWidth: "1400px",
        margin: "10vh auto",
      });
    },
    button() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
        background: "transparent",
      });
    },
    divider() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
      });
    },
    dropdown() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
      });
    },
    "cards-v2"() {
      setBreakpointStyle(1740);
      css(element, {
        height: "90vh",
        maxWidth: "1700px",
        margin: "5vh auto",
        background: "transparent",
      });
    },
    "easy-html"() {
      setBreakpointStyle(1740);
      css(element, {
        height: "90vh",
        maxWidth: "1700px",
        margin: "5vh auto",
        background: "transparent",
      });
    },
    "hidden-content"() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        width: "100%",
        maxWidth: "1400px",
        margin: "7.5vh auto",
      });
    },
    "image-carousel"() {
      setBreakpointStyle(1290);
      css(element, {
        height: "90vh",
        width: "90vw",
        maxWidth: "1250px",
        margin: "5vh auto",
      });
    },
    "numbered-headings"() {
      setBreakpointStyle(1360);
      css(element, {
        height: "80vh",
        width: "975px",
        margin: "10vh auto",
      });
    },
    "footnote-display"() {
      css(element, {
        height: "60vh",
        width: "775px",
        margin: "10vh auto",
      });
    },
    "page-break"() {
      css(element, {
        width: "675px",
        margin: "10vh auto",
      });
    },
    panel() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
        background: "transparent",
      });
    },
    "tab-group"() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
        background: "transparent",
      });
    },
    tab() {
      setBreakpointStyle(1440);
      css(element, {
        height: "85vh",
        maxWidth: "1400px",
        margin: "7.5vh auto",
        background: "transparent",
      });
    },
  };

  css(element, { background: "white" });

  if (macroConfig[macro]) {
    macroConfig[macro]();
  } else {
    setBreakpointStyle(1440);
    css(element, {
      height: "85vh",
      maxWidth: "1400px",
      margin: "7.5vh auto",
    });
  }
}

function loadScript(cfg) {
  const { url, global: globalName } = cfg;
  return new Promise((r, j) => {
    // Load the script
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = function () {
      r(globalName && window[globalName]);
    };
    script.onerror = function (err) {
      j(err);
    };
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  });
}

const DARK_MODE_SUPPORTED = [
  "cards-v2",
  "button",
  "announcement",
  "panel",
  "tabs",
  "divider",
  "image-slider",
  "tab-group",
  "tab",
  "title-and-text",
  "permissions",
  "macrosuite-configuration",
];

const App = () => {
  const [atlassianConnectLoaded, setAtlassianConnectLoaded] = useState(false);
  const [currentMode, setCurrentMode] = useState(Modes.VIEW);
  const [colorScheme, setColorScheme] = useState(DEFAULT_COLOR_MODE);
  const { t } = useTranslation();

  const selectedMacro = useRef("");
  const rootElementRef = useRef(document.documentElement);
  const theme = themes[colorScheme];

  useLanguageDetect();

  useEffect(() => {
    if (selectedMacro.current !== "designer") {
      window.AP?.theming?.initializeTheming();
    }
  }, [window?.AP]);

  useEffect(() => {
    if (
      DARK_MODE_SUPPORTED.find(
        (key) =>
          selectedMacro.current === key || window.location.href.includes(key)
      )
    ) {
      const rootElement = rootElementRef.current;
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.attributeName === "data-color-mode") {
            const colorMode = rootElement.getAttribute("data-color-mode");
            setColorScheme(colorMode || DEFAULT_COLOR_MODE);
          }
        });
      });
      observer.observe(rootElement, { attributes: true });
      if (!rootElement.hasAttribute("data-color-mode")) {
        setColorScheme(DEFAULT_COLOR_MODE);
      }
      return () => {
        observer.disconnect();
      };
    }
  }, [selectedMacro.current]);

  useEffect(() => {
    (async () => {
      selectedMacro.current = getParameterByName("macro");
      if (
        selectedMacro.current === "panel" ||
        selectedMacro.current === "tab-group"
      ) {
        await loadScript({
          url: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js",
          global: "jQuery",
        });
        await loadScript({
          url: "https://unpkg.com/@atlassian/aui@8.6.0/dist/aui/aui-css-deprecations.js",
        });
        await loadScript({
          url: "https://unpkg.com/@atlassian/aui@8.6.0/dist/aui/aui-prototyping.js",
        });
      }
      const isAtlassianScriptInitialized =
        await initializeAppInConfluenceContext();
      const element = document.getElementsByClassName("ac-content");
      const mode = getParameterByName("mode");
      const context = await window.AP.context.getContext();

      const invalidLicense = getParameterByName("invalid-license");

      if (invalidLicense) {
        element[0].classList.remove("hide");
        setAtlassianConnectLoaded(isAtlassianScriptInitialized);
        return;
      }

      if (
        context.confluence &&
        context.confluence.macro &&
        (context.confluence.macro.outputType === "display" ||
          context.confluence.macro.outputType === "preview")
      ) {
        element[0].classList.remove("hide");
      }

      if (mode === "custom-macro-edtior") {
        element[0].classList.remove("hide");
        if (element && element[0]) {
          individualMacroStyles(selectedMacro.current, element[0]);
        }
      }
      if (mode === "editor") {
        setCurrentMode(Modes.EDITOR);
        window.AP.dialog
          .create({
            key: "custom-macro-editor",
            width: "100%",
            height: "100%",
            customData: {
              macro: selectedMacro.current,
            },
          })
          .on("close", () => {
            window.AP.confluence.closeMacroEditor();
          });
        setAtlassianConnectLoaded(isAtlassianScriptInitialized);
      } else {
        element[0].classList.remove("hide");
        setAtlassianConnectLoaded(isAtlassianScriptInitialized);
      }
    })();
  }, []);

  if (!atlassianConnectLoaded) {
    return <div>Loading Optics</div>;
  }

  return (
    <ThemeContext.Provider value={theme}>
      <FlagsProvider>
        <RouterProvider router={router} />
      </FlagsProvider>
    </ThemeContext.Provider>
  );
};

export default App;
