/* eslint-disable prefer-promise-reject-errors */
const ALL_JS_URL = "https://connect-cdn.atl-paas.net/all.js";

const contextIsIframe = () => {
  if (!window.self || !window.top) {
    return true;
  }
  return window.self !== window.top;
};

export const loadScriptInHTMLHead = (scriptURL, onScriptLoaded) => {
  const scriptElement = document.createElement("script");
  scriptElement.onerror = (onError) => {
    throw new Error(`Could not load script: ${onError.target.src}`);
  };
  scriptElement.src = scriptURL;
  scriptElement.setAttribute("data-options", "sizeToParent:true;margin:false");
  scriptElement.onload = onScriptLoaded;

  document.head.appendChild(scriptElement);
};

export const initializeAppInConfluenceContext = () =>
  new Promise((resolve, reject) => {
    if (!contextIsIframe()) {
      reject("not loading scripts since its not the expected iframe");
    }
    loadScriptInHTMLHead(ALL_JS_URL, async () => {
      resolve(true);
    });
  });
