import { useEffect, useState } from "react";
import i18n from "../../i18n/i18n";

const LocaleToLanguage = {
  en_US: "en",
  en_GB: "en",
  // commented out for release
  // de_DE: "de",
  // da_DK: "da",
  // es_ES: "es",
  // fr_FR: "fr",
  // it_IT: "it",
  // nl_NL: "nl",
  // pt_BR: "pt",
  // pt_PT: "pt",
  // tr_TR: "tr",
};

export const useLanguageDetect = () => {
  const [locale, setLocale] = useState();

  useEffect(() => {
    if (window.AP) {
      window.AP?.user.getLocale((loc) => setLocale(loc.replace("_", "-")));
    }
  }, [window.AP]);

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(LocaleToLanguage[locale]);
    }
  }, [locale]);
};
